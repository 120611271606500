import {useMemo} from 'react'
import get from 'lodash/get.js'
import round from 'lodash/round.js'

import {
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  useSelector,
  getState,
} from '../../../store.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {
  autoFillPostageConfigSelector,
  shipperTypeSelector,
} from '../../../data/shippers.js'
import className from '../../../common/className.js'
import {showAutoFillPostageModal} from '../../Modals/AutoFillPostageModal.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import CurrencyInput from '../../../common/components/CurrencyInput.js'
import Quantity from '../../../common/components/Quantity.js'
import Currency, {formatCurrency} from '../../../common/components/Currency.js'
import {
  companySelector,
  currencySymbolSelector,
  hasAccountCreditCardSelector,
} from '../../../data/company.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {addToShipperBalance} from '../../../data/shipperBalances.js'
import {
  PAYMENT_TYPE_OPTIONS,
  anyPaymentAccountIsLockedSelector,
  defaultPaymentAccountSelector,
  hasInprogressAccountTransferSelector,
  NET_ZERO_PA,
  paymentAccountSelector,
  PLAID,
  postagePaymentAccountOptionsSelector,
  hasNeedsToFinishSetupSelector,
  hasPaymentAccountIntegrationIssueSelector,
  ACH_PT,
  SAME_DAY_ACH_PT,
} from '../../../data/paymentAccounts.js'
import {isNonZeroPositiveNumeric} from '../../../common/utils.js'
import {PITNEY, VISIBLE_USPS} from '../../../common/constants/ShipperNames.js'
import {amountExceedsLimitSelector} from '../../../data/account.js'
import {PostageBalanceWrapper} from '../../settings/Shippers/Forms/PostageBalance.js'
import Zelect, {DefaultListItem} from '../../../common/components/Zelect.js'

const MODAL_FORM = 'POSTAGE_BALANCE_MODAL'

export function showPostageBalanceModal(shipperID) {
  const defaultPaymentAccount = defaultPaymentAccountSelector(getState())

  setForm(MODAL_FORM, {
    shipperID,
    paymentAccountID: null,
    paymentType: null,
    addAmount: '',
    serverError: null,
    isSaving: false,
  })

  if (defaultPaymentAccount) {
    setPaymentAccount(defaultPaymentAccount.id)
  }
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export function errorsSelector(state) {
  const {shipperID, addAmount, paymentAccountID} = modalFormSelector(state)
  const shipperType = shipperTypeSelector(state, {shipperID})
  const hasAccountCreditCard = hasAccountCreditCardSelector(state)
  const hasInprogressAccountTransfer = paymentAccountID
    ? hasInprogressAccountTransferSelector(state, {paymentAccountID})
    : false
  const hasNeedsToFinishSetup = paymentAccountID
    ? hasNeedsToFinishSetupSelector(state, {paymentAccountID})
    : false
  const hasPaymentAccountIntegrationIssue = paymentAccountID
    ? hasPaymentAccountIntegrationIssueSelector(state, {paymentAccountID})
    : false
  const anyPaymentAccountIsLocked = anyPaymentAccountIsLockedSelector(state)
  const errors = {}

  if (!hasAccountCreditCard) {
    errors.creditCard = 'A payment method must be provided'
    errors.preventSave = true
  }

  if (!isNonZeroPositiveNumeric(addAmount)) {
    errors.addAmount = 'Amount must be a positive number'
    errors.preventSave = true
  }

  if (
    isNonZeroPositiveNumeric(addAmount) &&
    [PITNEY, VISIBLE_USPS].includes(shipperType) &&
    amountExceedsLimitSelector(state, {amount: Number(addAmount)})
  ) {
    errors.addAmount = 'Amount exceeds purchase limit'
    errors.preventSave = true
  }

  if (hasInprogressAccountTransfer) {
    errors.addAmount = 'Transfer has already started'
    errors.preventSave = true
  }

  if (anyPaymentAccountIsLocked) {
    errors.addAmount = 'Payment account is locked'
    errors.preventSave = true
  }

  if (hasNeedsToFinishSetup) {
    errors.addAmount = 'Payment account is not setup'
    errors.preventSave = true
  }

  if (hasPaymentAccountIntegrationIssue) {
    errors.addAmount = 'Payment account has an integration issue'
    errors.preventSave = true
  }

  return errors
}

export function showIncreasePostageLimitMessageSelector(state) {
  const {addAmount, serverError} = modalFormSelector(state)
  const amountExceedsLimit = amountExceedsLimitSelector(state, {
    amount: Number(addAmount) || 0,
  })

  return (
    amountExceedsLimit ||
    !!(
      serverError &&
      serverError.match(/You have exceeded your daily postage purchase limit/)
    )
  )
}

function setPaymentAccount(paymentAccountID) {
  const paymentAccount = paymentAccountSelector(getState(), {paymentAccountID})

  updateModalForm({
    paymentAccountID,
    paymentType: paymentAccount.payment_type,
  })
}

export async function addPostageToShipper() {
  try {
    updateModalForm({isSaving: true, serverError: null})

    const {shipperID, addAmount, paymentAccountID, paymentType} =
      modalFormSelector(getState())

    const amount = Number(addAmount)

    await addToShipperBalance(shipperID, amount, paymentType, paymentAccountID)

    if ([ACH_PT, SAME_DAY_ACH_PT].includes(paymentType)) {
      showMessageToast(
        `Initiated ${formatCurrency(
          amount,
          currencySymbolSelector(getState()),
        )} transfer for postage to USPS account`,
      )
    } else {
      showMessageToast(
        `Added ${formatCurrency(
          amount,
          currencySymbolSelector(getState()),
        )} postage to USPS account`,
      )
    }

    closeModal()
  } catch (err) {
    updateModalForm({
      serverError: err.error_message || err.message,
      isSaving: false,
    })
  }
}

function PaymentAccountOption({option, ...props}) {
  const paymentAccount = option.entity
  const account = get(paymentAccount, ['activation_response', 'account'])
  const {payment_backend, payment_method_response} = paymentAccount

  const hasInprogressAccountTransfer = useSelector((state) =>
    hasInprogressAccountTransferSelector(state, {
      paymentAccountID: paymentAccount.id,
    }),
  )
  const hasNeedsToFinishSetup = useSelector((state) =>
    hasNeedsToFinishSetupSelector(state, {paymentAccountID: paymentAccount.id}),
  )
  const hasPaymentAccountIntegrationIssue = useSelector((state) =>
    hasPaymentAccountIntegrationIssueSelector(state, {
      paymentAccountID: paymentAccount.id,
    }),
  )

  return (
    <DefaultListItem option={option} {...props}>
      {payment_backend === PLAID ? (
        <>
          <div>
            <strong className="fs-00 lh-md margin-right-3">
              {account.name}
            </strong>
            <span className="fs-00 lh-md unbold">
              (ending in {account.mask})
            </span>
          </div>
          {account.official_name && (
            <div className="op-75 margin-top-3 unbold">
              {account.official_name}
            </div>
          )}
          {account.locked && (
            <div className="margin-top-5">
              <strong className="label__callout label__callout--red fs-n2">
                ACCOUNT LOCKED
              </strong>
            </div>
          )}
          {hasInprogressAccountTransfer && (
            <div className="margin-top-5">
              <strong className="label__callout label__callout--blue fs-n2">
                Transfer in Progress
              </strong>
            </div>
          )}
          {hasNeedsToFinishSetup && (
            <div className="margin-top-5">
              <strong className="label__callout label__callout--yellow fs-n2">
                Needs to Finish Setup
              </strong>
            </div>
          )}
          {hasPaymentAccountIntegrationIssue && (
            <div className="margin-top-5">
              <strong className="label__callout label__callout--red fs-n2">
                Needs Attention
              </strong>
            </div>
          )}
        </>
      ) : payment_backend === NET_ZERO_PA ? (
        <>
          <div>
            <strong className="fs-00 lh-md margin-right-3">
              {payment_method_response.card_type}
            </strong>
            <span className="fs-00 lh-md">
              (ending in {payment_method_response.last4})
            </span>
          </div>
          <div className="op-75 margin-top-3">
            expires {payment_method_response.expiry_month}/
            {payment_method_response.expiry_year}
          </div>
        </>
      ) : (
        option.display
      )}
    </DefaultListItem>
  )
}

function PostageBalanceModal({form}) {
  const errors = useSelector(errorsSelector)
  const {isEnabled} = useSelector((state) =>
    autoFillPostageConfigSelector(state, {shipperID: form.shipperID}),
  )
  const showIncreasePostageLimitMessage = useSelector((state) =>
    showIncreasePostageLimitMessageSelector(state, {shipperID: form.shipperID}),
  )
  const {credit_card_processing_fee} = useSelector(companySelector)
  const postagePaymentAccountOptions = useSelector(
    postagePaymentAccountOptionsSelector,
  )
  const paymentAccount = useSelector((state) =>
    form.paymentAccountID
      ? paymentAccountSelector(state, {paymentAccountID: form.paymentAccountID})
      : null,
  )
  const anyPaymentAccountIsLocked = useSelector(
    anyPaymentAccountIsLockedSelector,
  )
  const paymentTypeOptions = useMemo(() => {
    if (!paymentAccount) {
      return []
    }

    // only include payment types that have associated processing fees
    return PAYMENT_TYPE_OPTIONS.filter(
      ({value}) => paymentAccount.processing_fee[value],
    )
  }, [paymentAccount])

  const [totalCharge, amount, fee, percentage, cap, charge] = useMemo(() => {
    const amount = Number(form.addAmount) || 0
    let percentage = credit_card_processing_fee || 0
    let cap = Infinity
    let charge = 0

    if (paymentAccount) {
      const processing_fee = paymentAccount.processing_fee[form.paymentType]

      if (processing_fee) {
        percentage = processing_fee.percentage || 0
        cap = processing_fee.cap || Infinity
        charge = processing_fee.charge || 0
      }
    }

    let fee = charge + amount * round(percentage, 4)
    fee = fee > cap ? cap : fee
    const totalCharge = amount + fee

    return [totalCharge, amount, fee, percentage, cap, charge]
  }, [
    form.addAmount,
    form.paymentType,
    credit_card_processing_fee,
    paymentAccount,
  ])

  const hasBaseFee = charge > 0
  const hasMaxFee = cap > 0 && cap !== Infinity

  return (
    <ConfirmModal
      title="Add Funds to Postage Balance"
      confirmText="Add Postage"
      cancelText="Cancel"
      onConfirm={() => addPostageToShipper()}
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      <PostageBalanceWrapper shipperID={form.shipperID}>
        <dd
          className={className`alert ${
            isEnabled ? 'alert--success' : 'alert--neutral'
          } inline-block`}
        >
          {isEnabled ? (
            <strong className="fs-n0 lh-md v-align-middle margin-right-10">
              Postage auto-fill is enabled
            </strong>
          ) : (
            <span className="fs-n0 lh-md v-align-middle margin-right-10">
              Postage auto-fill is disabled
            </span>
          )}
          <ButtonPrimary
            isOutlined
            size="xx-sm"
            onClick={() => showAutoFillPostageModal(form.shipperID)}
          >
            Manage
          </ButtonPrimary>
        </dd>
        {postagePaymentAccountOptions.length > 1 && (
          <dd className="list__item margin-bottom-15">
            <div className="divider--top margin-top-20 padding-top-15 w-50">
              <Zelect
                label="Which account do you want to use?"
                id="payment_account"
                value={form.paymentAccountID}
                onChange={(option) => setPaymentAccount(option.value)}
                options={postagePaymentAccountOptions}
                OptionComponent={PaymentAccountOption}
              />
              {anyPaymentAccountIsLocked && (
                <div className="alert alert--error">
                  <p className="fs-00 lh-md margin-bottom-10">
                    <strong>
                      ACH transfers have been temporarily suspended in your
                      Ordoro account. Please contact our Support team to get
                      this issue resolved.
                    </strong>
                  </p>
                </div>
              )}
            </div>
          </dd>
        )}
        {paymentTypeOptions.length > 1 && (
          <dd className="list__item margin-bottom-15">
            <div className="w-50">
              <Zelect
                label="Select an ACH processing speed:"
                id="payment_type"
                value={form.paymentType}
                onChange={(option) => {
                  updateModalForm({
                    paymentType: option.value,
                  })
                }}
                options={paymentTypeOptions}
              />
            </div>
          </dd>
        )}
        {!anyPaymentAccountIsLocked && (
          <dd className="list__item margin-bottom-20">
            <div className="w-50">
              <CurrencyInput
                label="How much money do you want to add?"
                id="add_amount"
                placeholder="0.00"
                value={form.addAmount}
                onChange={(value) =>
                  updateModalForm({
                    addAmount: value,
                    hasChanged_addAmount: true,
                  })
                }
                errorMessage={form.hasChanged_addAmount && errors.addAmount}
              />
              {showIncreasePostageLimitMessage && (
                <small className="error error-message">
                  {'Please contact Ordoro support to increase this limit. '}
                  <a
                    href="https://support.ordoro.com/how-do-i-increase-my-daily-postage-purchase-limit/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more.
                  </a>
                </small>
              )}
            </div>
            {fee > 0 && form.addAmount > 0 && (
              <div className="w-50 margin-top-10 margin-bottom-0 alert alert--standard">
                <div className="fs-00 lh-md text--dk-grey">
                  <strong className="margin-right-1">Additional Fees</strong>{' '}
                  {hasMaxFee && (
                    <span className="fs-n0 text--md-grey">
                      <em>
                        (<Currency value={cap} /> cap)
                      </em>
                    </span>
                  )}
                </div>
                <div className="fs-n0 text--md-grey margin-top-7">
                  <strong>
                    <Quantity
                      value={percentage * 100}
                      options={{
                        style: 'decimal',
                        maximumFractionDigits: 2,
                      }}
                    />
                    % Processing Fee:
                  </strong>{' '}
                  <Currency value={amount * percentage} />
                </div>
                {hasBaseFee && (
                  <div className="fs-n0 text--md-grey">
                    <strong>Base Fee:</strong> <Currency value={charge} />
                  </div>
                )}
                {hasBaseFee && hasMaxFee && (
                  <div className="fs-n0 text--md-grey">
                    <strong>Total Fees:</strong> <Currency value={fee} />
                  </div>
                )}
                <div className="fs-n0 divider--top text--dk-grey">
                  <strong>Total to be Charged:</strong>{' '}
                  <Currency value={totalCharge} />
                </div>
              </div>
            )}
          </dd>
        )}
      </PostageBalanceWrapper>
    </ConfirmModal>
  )
}

export default onlyIfForm(PostageBalanceModal, modalFormSelector)
