import PropTypes from 'prop-types'
import {useState} from 'react'
import uniqueId from 'lodash/uniqueId.js'

import {SelectOptionValue} from '../../PropTypes.js'
import {HK_SEND_TO_PRINTER_A} from '../../constants/HotKeys.js'
import ButtonPrimary from './ButtonPrimary.js'
import ButtonLink from './ButtonLink.js'
import {navigate} from '../../../redux/actions/ui/index.js'
import {
  canUsePrintNodePrintingSelector,
  isPrinterOnlineSelector,
  printerNameSelector,
  createPrintJob,
  pollPrintJob,
} from '../../../data/printNode.js'
import {
  dispatch,
  getState,
  updateForm,
  useAutoForm,
  useSelector,
} from '../../../store.js'
import {Count, plural} from '../Plural.js'
import {showMessageToast} from '../../../ordoro/Header/Toast/index.js'
import {showGlobalError} from '../../../ordoro/GlobalErrorMessage.js'

export async function sendToPrintNode({
  formKey,
  pdfLink,
  pdfFile,
  pdfName,
  paperType,
  printerID,
  documentCount,
}) {
  try {
    updateForm(formKey, {isProcessing: true})

    const printJobID = await createPrintJob({
      pdfLink,
      printerID,
      pdfName,
      blob: pdfFile,
      paper: paperType,
    })

    await pollPrintJob(printJobID)

    const printerName = printerNameSelector(getState(), {printerID})

    showMessageToast(
      plural(documentCount)`${Count} ${pdfName.toLowerCase()}s ${[
        'were',
        'was',
      ]} sent to ${printerName}`,
    )
  } catch (err) {
    showGlobalError(`Error creating print job: ${err.message}`, err)
  } finally {
    updateForm(formKey, {isProcessing: false})
  }
}

export default function ButtonSendToPrinter({
  pdfLink,
  title,
  className,
  hotKey,
  documentCount,
  printerID,
  onClick,
  buttonText,
  isDisabled,
}) {
  title = title || 'Document'
  documentCount = documentCount || 1
  buttonText = buttonText || 'InstaPrint'
  const [formKey] = useState(() => uniqueId('BUTTON_SEND_TO_PRINTER_'))

  const canUsePrintNodePrinting = useSelector(canUsePrintNodePrintingSelector)
  const isPrinterOnline = useSelector((state) =>
    isPrinterOnlineSelector(state, {printerID}),
  )
  const needsSetup = !canUsePrintNodePrinting || !printerID || !isPrinterOnline
  isDisabled = isDisabled === true || needsSetup

  const form = useAutoForm(formKey, {
    isProcessing: false,
  })

  return (
    <>
      <ButtonPrimary
        className={className}
        isOutlined={isDisabled}
        isLoading={form.isProcessing}
        isDisabled={isDisabled}
        isDisabledWarning={!printerID && canUsePrintNodePrinting}
        isDisabledError={!isPrinterOnline}
        alt
        onClick={() => {
          if (onClick) {
            onClick({canPrint: true, printButtonFormKey: formKey})
          }

          if (pdfLink) {
            sendToPrintNode({
              formKey,
              pdfLink,
              pdfName: title,
              printerID,
              documentCount,
            })
          }
        }}
        hotKey={hotKey || HK_SEND_TO_PRINTER_A}
      >
        <span className="button__text">{buttonText}</span>
      </ButtonPrimary>

      {needsSetup && (
        <div>
          <ButtonLink
            className={`block fs-n2 ${
              !printerID
                ? 'text--warning'
                : !isPrinterOnline && canUsePrintNodePrinting
                  ? 'error'
                  : !canUsePrintNodePrinting
                    ? 'enable-instaprint'
                    : ''
            }`}
            onClick={() => {
              if (onClick) {
                onClick({canPrint: false})
              }

              dispatch(navigate('#/settings/printing'))
            }}
          >
            {!canUsePrintNodePrinting
              ? 'Enable InstaPrint →'
              : !printerID
                ? 'Assign printer →'
                : 'Printer is offline'}
          </ButtonLink>
        </div>
      )}
    </>
  )
}

ButtonSendToPrinter.propTypes = {
  pdfLink: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  hotKey: PropTypes.string,
  printerID: SelectOptionValue,
  documentCount: PropTypes.number,
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  isDisabled: PropTypes.bool,
}
