import get from 'lodash/get.js'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  useSelector,
  formSelector,
} from '../../store.js'
import ConfirmModal from '../../common/components/Modal/ConfirmModal.js'
import deferPromise from '../../common/deferPromise.js'
import verde from '../../common/verde.js'
import {showMessageToast} from '../Header/Toast/index.js'
import {
  getPaymentAccounts,
  getPaymentAccount,
  paymentAccountSelector,
  postagePaymentAccountsSelector,
  updatePaymentAccount,
  hasPaymentAccountIntegrationIssueSelector,
  PLAID,
  NET_ZERO_PA,
  netZeroPaymentAccountSelector,
  anyPaymentAccountIsLockedSelector,
  PAYMENT_TYPE_OPTIONS,
  hasNeedsToFinishSetupSelector,
} from '../../data/paymentAccounts.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import api from '../../common/api.js'
import {showConfirmMessageModal} from '../../common/components/Modal/ConfirmMessageModal.js'
import loadPlaid from '../../libs/plaid.js'
import TextInput from '../../common/components/TextInput.js'
import {isPresent} from '../../common/utils.js'
import className from '../../common/className.js'
import {showAccountCreditCardModal} from './AccountCreditCardModal.js'
import Zelect from '../../common/components/Zelect.js'

const MODAL_FORM = 'PAYMENT_ACCOUNTS_MODAL'
const CACHED_PLAID_LINK_TOKEN = 'plaid.link_token'

export function showPaymentAccountsModal() {
  const deferredPromise = deferPromise()

  const link_token_json = localStorage.getItem(CACHED_PLAID_LINK_TOKEN)

  let link_token
  if (link_token_json) {
    try {
      const json = JSON.parse(link_token_json)

      if (new Date(json.expiration) > new Date()) {
        link_token = json.link_token
      } else {
        localStorage.removeItem(CACHED_PLAID_LINK_TOKEN)
      }
    } catch (err) {
      // not good but do not care
    }
  }

  setForm(MODAL_FORM, {
    link_token,
    autoStart: !!link_token,
    isSaving: false,
    isLoading: false,
    plaidResultError: null,
    serverError: null,
    deferredPromise,
  })

  return deferredPromise.promise
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal(hasCreditCard = false) {
  localStorage.removeItem(CACHED_PLAID_LINK_TOKEN)

  const form = modalFormSelector(getState())

  removeForm(MODAL_FORM)

  form.deferredPromise.resolve(hasCreditCard)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export async function deletePaymentAccount(paymentAccountID) {
  const willDelete = await showConfirmMessageModal({
    title: 'Delete Payment Account',
    message: 'Do you want to delete this payment account?',
  })

  if (!willDelete) {
    return
  }

  try {
    updateModalForm({serverError: null})

    await api.delete(`/company/payment/${paymentAccountID}`)

    showMessageToast('Deleted Payment Account')

    await getPaymentAccounts()
  } catch (err) {
    updateModalForm({serverError: err.message || err.error_message})
  }
}

export async function makePaymentAccountDefault(paymentAccountID) {
  const willChange = await showConfirmMessageModal({
    title: 'Set Default Payment Method',
    message:
      'Do you want to make this payment account your default payment method?',
  })

  if (!willChange) {
    return
  }

  try {
    updateModalForm({serverError: null})

    for (const paymentAccount of postagePaymentAccountsSelector(getState())) {
      if (!paymentAccount.is_default) {
        continue
      }

      await api.put(`/company/payment/${paymentAccount.id}`, {
        is_default: false,
      })
    }

    await api.put(`/company/payment/${paymentAccountID}`, {is_default: true})

    showMessageToast('Updated Payment Account')

    await getPaymentAccounts()
  } catch (err) {
    updateModalForm({serverError: err.message || err.error_message})
  }
}

export async function getLinkToken(params) {
  try {
    const {json} = await verde.post('/plaid/create_link_token', params)

    updateModalForm({
      link_token: json.link_token,
      link_token_expiration: json.expiration,
    })

    localStorage.setItem(CACHED_PLAID_LINK_TOKEN, JSON.stringify(json))
  } catch (err) {
    updateModalForm({serverError: err.message || err.error_message})
  }
}

export async function startLink(paymentAccountID) {
  const Plaid = await loadPlaid()

  const {link_token} = modalFormSelector(getState())

  if (!link_token) {
    return
  }

  const handler = Plaid.create({
    token: link_token,
    onSuccess: (public_token) => onSuccess(public_token, paymentAccountID),
    onEvent,
    onExit,
    onLoad: () => {
      updateModalForm({isLoading: false})
    },
  })

  handler.open()
}

export async function onSuccess(public_token, paymentAccountID) {
  try {
    const {
      json: {payment_account: touchedPaymentAccounts},
    } = await verde.post('/plaid/public_token_exchange', {
      public_token,
      payment_account_id: paymentAccountID,
    })

    showMessageToast('Added Bank Account')

    await getPaymentAccounts()

    // ensure we have a legal name for each account.
    // some workflows will give us the legal name but some
    // do not have permission
    for (const paymentAccount of touchedPaymentAccounts) {
      if (paymentAccount.activation_response.legal_name) {
        continue
      }

      await editLegalName(paymentAccount.id)
    }
  } catch (err) {
    updateModalForm({serverError: err.message || err.error_message})
  }
}

export async function onEvent(_eventName, _metadata) {
  // log onEvent callbacks from Link
  // https://plaid.com/docs/link/web/#onevent
}

export async function onExit(_error, _metadata) {
  // log onExit callbacks from Link, handle errors
  // https://plaid.com/docs/link/web/#onexit

  showMessageToast('Account Setup Cancelled')
}

async function createAccount() {
  updateModalForm({isLoading: true})

  await getLinkToken()

  await startLink()
}

async function resumeLink(paymentAccountID) {
  const paymentAccount = paymentAccountSelector(getState(), {paymentAccountID})
  const hasPaymentAccountIntegrationIssue =
    hasPaymentAccountIntegrationIssueSelector(getState(), {paymentAccountID})

  const params = {}

  const access_token = get(paymentAccount, [
    'activation_response',
    'access_token',
  ])
  const authorization_id = get(paymentAccount, [
    'payment_method_response',
    'authorization',
    'id',
  ])

  if (hasPaymentAccountIntegrationIssue && authorization_id) {
    params.authorization_id = authorization_id
  } else if (access_token) {
    params.access_token = access_token
  }

  updateModalForm({isLoading: true})

  await getLinkToken(params)

  await startLink(paymentAccountID)
}

async function editLegalName(paymentAccountID) {
  try {
    let paymentAccount = paymentAccountSelector(getState(), {paymentAccountID})
    let {
      activation_response: {account = {}, legal_name},
      payment_type,
    } = paymentAccount
    const paymentTypeOptions = PAYMENT_TYPE_OPTIONS.filter(
      ({value}) => paymentAccount.processing_fee[value],
    )

    const results = await showConfirmMessageModal({
      title: 'Edit Payment Account',
      Message({form, setModalFormValue}) {
        return (
          <>
            <div>
              <strong className="fs-01 lh-md margin-right-3">
                {account.name}
              </strong>
              <span className="fs-01 lh-md">(ending in {account.mask})</span>
            </div>
            <div className="fs-00 op-75 margin-top-3 margin-bottom-15">
              {account.official_name}
            </div>
            <TextInput
              label="Full Legal Name for Account"
              id="legal_name"
              required
              value={form.confirmValue.legal_name}
              onChange={(value) =>
                setModalFormValue('confirmValue.legal_name', value)
              }
            />
            {paymentTypeOptions.length > 1 && (
              <Zelect
                labelClassName="margin-top-10"
                label="Select an ACH processing speed:"
                id="payment_type"
                value={form.confirmValue.payment_type}
                onChange={(option) => {
                  setModalFormValue('confirmValue.payment_type', option.value)
                }}
                options={paymentTypeOptions}
              />
            )}
          </>
        )
      },
      confirmText: 'Save',
      cancelText: 'Cancel',
      errorSelector(state, {formName}) {
        const errors = {}
        const {
          confirmValue: {legal_name},
        } = formSelector(state, {formName})

        if (!isPresent(legal_name)) {
          errors.legal_name = 'Full Legal Name is required'
          errors.preventSave = true
        }
      },
      confirmValue: {legal_name: legal_name || '', payment_type},
    })

    if (results === false) {
      return
    }

    legal_name = results.legal_name
    payment_type = results.payment_type

    paymentAccount = await getPaymentAccount(paymentAccountID)

    const params = {
      activation_response: {
        ...paymentAccount.activation_response,
        legal_name,
      },
      payment_type,
    }

    await updatePaymentAccount(paymentAccountID, params)
  } catch (err) {
    updateModalForm({serverError: err.message || err.error_message})
  }
}

/**
 * Payment Account Row
 *
 * Display the payment account row in the modal
 *
 * @param {Object} props
 * @param {Object} props.paymentAccount
 * @param {Number} props.paymentAccount.id
 * @param {Object} props.paymentAccount.activation_response
 * @param {Boolean} props.paymentAccount.is_default
 * @param {String} props.paymentAccount.payment_backend
 * @param {Object} [props.paymentAccount.payment_method_response]
 * @param {Boolean} props.anyPaymentAccountIsLocked
 * @returns {ReactNode}
 */
function PaymentAccountRow({
  paymentAccount: {
    id,
    activation_response,
    is_default,
    payment_backend,
    payment_method_response,
  },
  anyPaymentAccountIsLocked,
}) {
  const {account = {}, legal_name} = activation_response
  const hasPaymentAccountIntegrationIssue = useSelector((state) =>
    hasPaymentAccountIntegrationIssueSelector(state, {paymentAccountID: id}),
  )
  const hasNeedsToFinishSetup = useSelector((state) =>
    hasNeedsToFinishSetupSelector(state, {paymentAccountID: id}),
  )
  const canResumeLink =
    (hasPaymentAccountIntegrationIssue || hasNeedsToFinishSetup) &&
    !anyPaymentAccountIsLocked

  return (
    <tr>
      <td className="table__td">
        <div
          className={className`wrap--account-cell ${{
            'text--warning': hasPaymentAccountIntegrationIssue,
          }}`}
        >
          {payment_backend === PLAID ? (
            <>
              <div>
                <strong className="fs-00 lh-md margin-right-3">
                  {account.name}
                </strong>
                <span className="fs-00 lh-md">(ending in {account.mask})</span>
              </div>
              <div className="op-75 margin-top-3">{account.official_name}</div>
              <div className="flex margin-top-5">
                {canResumeLink && (
                  <div className="">
                    <ButtonPrimary
                      size="xx-sm"
                      className="btn--primary-warning-ol margin-right-7"
                      isOutlined
                      onClick={() => resumeLink(id)}
                    >
                      {hasPaymentAccountIntegrationIssue
                        ? 'Reauthorize'
                        : 'Finish Setup'}
                    </ButtonPrimary>
                  </div>
                )}
                <ButtonLink className="fs-n1" onClick={() => editLegalName(id)}>
                  Edit
                </ButtonLink>
              </div>
            </>
          ) : payment_backend === NET_ZERO_PA ? (
            <>
              <div>
                <strong className="fs-00 lh-md margin-right-3">
                  {payment_method_response.card_type}
                </strong>
                <span className="fs-00 lh-md">
                  (ending in {payment_method_response.last4})
                </span>
              </div>
              <div className="op-75 margin-top-3">
                expires {payment_method_response.expiry_month}/
                {payment_method_response.expiry_year}
              </div>
            </>
          ) : null}
        </div>
      </td>
      <td className="table__td">
        {payment_backend === PLAID ? (
          <ButtonLink className="fs-n0" onClick={() => editLegalName(id)}>
            {legal_name || 'Edit'}
          </ButtonLink>
        ) : payment_backend === NET_ZERO_PA ? (
          <span className="fs-n0">{payment_method_response.name}</span>
        ) : null}
      </td>
      <td className="table__td">
        <span className="fs-00">
          {payment_backend === PLAID ? 'Bank/ACH' : 'Credit Card'}
        </span>
      </td>
      <td className="table__td table__td--md align-right">
        {anyPaymentAccountIsLocked ? (
          <strong className="label__callout label__callout--red fs-n1">
            ACCOUNT LOCKED
          </strong>
        ) : is_default ? (
          <strong className="label__callout label__callout--blue fs-n1">
            DEFAULT
          </strong>
        ) : (
          <ButtonPrimary
            className="btn--make-default-td"
            size="xx-sm"
            isOutlined
            onClick={() => makePaymentAccountDefault(id)}
          >
            Make Default
          </ButtonPrimary>
        )}
      </td>
      <td className="table__td table__td--md align-right">
        <ButtonLink
          className="no-underline"
          title="Remove Account"
          onClick={() => deletePaymentAccount(id)}
          disabled={anyPaymentAccountIsLocked}
        >
          <span className="i-trash fs-00" aria-hidden="true"></span>
        </ButtonLink>
      </td>
    </tr>
  )
}

/**
 * Manage Payment Accounts
 *
 * @param {Object} props
 * @param {Object} props.form - modal form
 * @param {String} [props.form.link_token] - Link to open
 * @param {Boolean} props.form.autoStart
 * @param {Boolean} props.form.isSaving
 * @param {Boolean} props.form.isLoading
 * @param {String} [props.form.plaidResultError]
 * @param {String} [props.form.serverError]
 * @returns {ReactNode}
 */
function PaymentAccountsModal({form}) {
  const paymentAccounts = useSelector(postagePaymentAccountsSelector)
  const netZeroPaymentAccount = useSelector(netZeroPaymentAccountSelector)
  const anyPaymentAccountIsLocked = useSelector(
    anyPaymentAccountIsLockedSelector,
  )

  return (
    <ConfirmModal
      title="Your Postage Payment Methods"
      modalSize="md"
      onCancel={() => closeModal()}
      cancelText="Close"
      isSaving={form.isSaving}
      error={form.serverError || form.plaidResultError}
    >
      <p className="fs-01 lh-md margin-bottom-15">
        <strong>
          Manage credit card and bank accounts that are used for USPS postage
          purchases.
        </strong>
      </p>
      {anyPaymentAccountIsLocked && (
        <div className="alert alert--error">
          <p className="fs-00 lh-md margin-bottom-0">
            <strong>
              Transfers for your postage balance have been temporarily suspended
              in your Ordoro account. Please contact our Support team to get
              this resolved.
            </strong>
          </p>
        </div>
      )}
      {paymentAccounts.length ? (
        <>
          <table className="table fs-00 margin-top-15">
            <thead>
              <tr>
                <th className="table__th table__th--md align-left">
                  Card/Account
                </th>
                <th className="table__th table__th--md align-left">
                  Name on Account
                </th>
                <th className="table__th table__th--md">Type</th>
                <th className="table__th table__th--md">&nbsp;</th>
                <th className="table__th table__th--md">&nbsp;</th>
              </tr>
            </thead>
            <tbody className="table__tbody table__tbody--lines">
              {paymentAccounts.map((paymentAccount) => (
                <PaymentAccountRow
                  key={paymentAccount.id}
                  paymentAccount={paymentAccount}
                  anyPaymentAccountIsLocked={anyPaymentAccountIsLocked}
                />
              ))}
            </tbody>
          </table>
          <hr className="margin-top-10" />
          <div className="flex">
            <ButtonPrimary
              size="sm"
              className="margin-right-15"
              isLoading={form.isLoading}
              onClick={() => showAccountCreditCardModal()}
              isDisabled={anyPaymentAccountIsLocked}
            >
              {netZeroPaymentAccount
                ? 'Update Credit Card'
                : 'Add a Credit Card'}
            </ButtonPrimary>
            <ButtonPrimary
              alt
              size="sm"
              onClick={() => createAccount()}
              isLoading={form.isLoading}
              isDisabled={anyPaymentAccountIsLocked}
            >
              Add a Bank/ACH Account
            </ButtonPrimary>
          </div>
        </>
      ) : (
        <div className="flex margin-top-20 margin-bottom-20">
          <ButtonPrimary
            size="md"
            className="margin-right-15"
            isLoading={form.isLoading}
            onClick={() => showAccountCreditCardModal()}
          >
            Add a Credit Card
          </ButtonPrimary>
          <ButtonPrimary
            alt
            size="md"
            onClick={() => createAccount()}
            isLoading={form.isLoading}
          >
            Add a Bank/ACH Account
          </ButtonPrimary>
        </div>
      )}
    </ConfirmModal>
  )
}

export default onlyIfForm(PaymentAccountsModal, (state) => {
  let form = modalFormSelector(state)

  if (!form && localStorage.getItem(CACHED_PLAID_LINK_TOKEN)) {
    showPaymentAccountsModal()

    form = modalFormSelector(state)
  }

  return form
})
